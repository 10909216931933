<template>
  <div>
    <c-table
      ref="table"
      title="선택 공정의 HAZOP 위험등록부 등재"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="450px"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      :hide-bottom="true"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-hazop-non-riskbook',
  props: {
    selectProcessCd: {
      type: String,
      default: function() {
        return '';
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            label: '도면',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            label: '노드',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'deviationName',
            field: 'deviationName',
            label: '이탈',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'nodeVariableDesignIntent',
            field: 'nodeVariableDesignIntent',
            label: '설계의도',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            label: '개선전',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'after',
            field: 'after',
            label: '개선후',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                label: '빈도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                label: '강도',
                align: 'center',
                style: 'width:40px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                label: '위험도',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            style: 'width:80px',
          },
        ],
        data: [],
      },
      listUrl: '',
    };
  },
  watch: {
    'selectProcessCd'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.ram.hazop.riskReduce.list.url;
      this.getList();
    },
    getList() {
      if (!this.selectProcessCd) {
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processCd: this.selectProcessCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data.riskbooks;
      },);
    },
    /* eslint-disable no-unused-vars */
  }
};
</script>
